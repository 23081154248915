<template>
  <v-container fluid pa-5 fill-height>
    <Create
      v-if="clients"
      :schema="fieldSchema"
      :dialog="dialog"
      @close="dialog = false"
    />
    <v-card
      tile
      min-height="100%"
      :style="
        'height:' + (height - 66) + 'px; overflow-y: auto; overflow-x: hidden'
      "
    >
      <v-card-title class="text-left"
        ><h1 class="h1">User Manager</h1></v-card-title
      >
      <v-card-actions>
        <v-divider></v-divider>
      </v-card-actions>
      <v-card-actions>
        <!-- search field card/tile -->
        <v-card tile width="100%" class="pb-10" elevation="1">
          <v-card-subtitle class="text-start">Search</v-card-subtitle>
          <v-card-actions>
            <v-card-actions style="width: 20%">
              <v-autocomplete
                v-model="usersByClient"
                :items="clients"
                item-title="client_name"
                item-value="id"
                prepend-inner-icon="mdi-filter"
                label="Users by Client"
                variant="outlined"
                density="compact"
                rounded="0"
                align-center
                return-object
                autocomplete="off"
              >
              </v-autocomplete>
            </v-card-actions>
            <v-card-actions style="width: 20%">
              <v-autocomplete
                v-model="autocompleteUser"
                no-data-text="no results"
                :loading="loading"
                :items="searchResults"
                item-title="text"
                item-value="value"
                v-model:search="search"
                flat
                hide-no-data
                label="Search By Name"
                clearable
                variant="outlined"
                density="compact"
                rounded="0"
                return-object
                autocomplete="off"
              ></v-autocomplete>
            </v-card-actions>
            <v-card-actions style="width: 30%">
              <v-btn
                variant="elevated"
                color="primary"
                tile
                @click="dialog = true"
                class="mb-6 ml-5"
                :disabled="!clients.length"
                aria-label="Create New User"
              >
                Create New User
              </v-btn>
              <v-btn
                variant="elevated"
                color="success"
                tile
                @click="downloadAllUsers"
                class="mb-6 ml-5"
                aria-label="Download All Users"
                :loading="downloadAllUsersLoading"
              >
                Download All Users
              </v-btn>
              <v-btn
                variant="elevated"
                color="blue"
                tile
                @click="bulkImportDialog = true"
                class="mb-6 ml-5 white--text"
                aria-label="import users"
              >
                import users
              </v-btn>
            </v-card-actions>
          </v-card-actions>
        </v-card>
      </v-card-actions>
      <v-card-text v-if="selectedUser" class="pa-0">
        <v-container fluid class="pa-0">
          <v-row dense>
            <v-col cols="10">
              <v-card tile class="pa-5">
                <v-card-actions class="pb-0">
                  <v-form
                    ref="form"
                    v-model="validation"
                    lazy-validation
                    class="pb-0"
                  >
                    <v-row style="padding-top: 15px !important" class="pb-0">
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        v-for="(item, key) in fieldSchema"
                        :key="key"
                        class="pb-0 pt-0"
                      >
                        <div v-if="fieldSchema[key].useInEditMode">
                          <v-checkbox
                            v-if="key === 'is_public_site_user_profile'"
                            v-model="selectedUser[key]"
                            :label="fieldSchema[key].label"
                            :disabled="publicCheckboxDisabled"
                          ></v-checkbox>
                          <v-autocomplete
                            v-else-if="key === 'client_id'"
                            v-model="selectedUser[key]"
                            :items="fieldSchema[key].selectItems"
                            item-title="client_name"
                            item-value="id"
                            :disabled="clientDisabled"
                            :label="fieldSchema[key].label"
                            variant="outlined"
                            density="compact"
                            rounded="0"
                            class="fields"
                            autocomplete="off"
                          >
                          </v-autocomplete>
                          <Field
                            v-else
                            :schema="fieldSchema[key]"
                            v-model:value="selectedUser[key]"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-actions>
                <v-card-actions
                  v-if="!selectedUser.is_public_site_user_profile"
                  class="pl-0"
                >
                  <v-radio-group
                    v-model="selectedUser.users_module_group_membership"
                    label="Group Membership"
                    hint="Select Module Membership"
                  >
                    <v-radio
                      v-for="group in clientUserModuleGroups"
                      :key="group.id"
                      :label="group.name"
                      :value="group.id"
                    ></v-radio>
                  </v-radio-group>
                </v-card-actions>
                <v-card-actions>
                  <v-btn
                    variant="elevated"
                    color="warning"
                    tile
                    @click="resetPassword()"
                    aria-label="Reset Password"
                  >
                    Reset Password
                  </v-btn>
                  <v-btn
                    variant="elevated"
                    color="error"
                    tile
                    @click="deleteUser()"
                    aria-label="Delete"
                  >
                    Delete
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    variant="elevated"
                    color="success"
                    tile
                    @click="updateUser()"
                    aria-label="Save Changes"
                  >
                    Save Changes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog v-model="usersByClientDialog" scrollable max-width="500px">
      <v-card>
        <v-toolbar
          v-if="usersByClient"
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h5 pl-6"
          >users for: {{ usersByClient.client_name }}</v-toolbar
        >
        <v-card-text style="height: 400px">
          <v-list-item
            link
            two-line
            v-for="item in clientsUsers"
            :key="item.id"
            @click="
              autocompleteUser = { value: item.id, text: item.name };
              getUser();
              usersByClientDialog = false;
            "
          >
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-btn
            variant="elevated"
            color="error"
            tile
            @click="usersByClientDialog = false"
            aria-label="Close"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="bulkImportDialog"
      scrollable
      persistent
      :max-width="failedOnes.length ? '800px' : '500px'"
    >
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h6 text-center"
          max-height="64px"
        >
          <v-spacer>
            <v-toolbar-title>Bulk import users </v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-container :style="dynamicSize" class="pa-0 pb-4">
          <!-- loading UX -->
          <v-card-actions
            v-if="importingProcess"
            tile
            elevation="0"
            class="progress"
          >
            <div class="progressText">
              <v-progress-circular
                :size="200"
                :width="3"
                color="primary"
                indeterminate
                >Importing users... <br />
                This may take a while
              </v-progress-circular>
            </div>
          </v-card-actions>
          <v-card-text v-else class="mt-4 pb-1">
            <v-file-input
              v-if="!failedOnes.length"
              v-model="importFile"
              id="fileImport"
              accept=".csv"
              clearable
              variant="outlined"
              density="compact"
              rounded="0"
              label="Select File"
              class="fields"
              :error-messages="fileError"
              @change="fileError = ''"
            ></v-file-input>
            <!--   table with the failed ones   -->
            <div v-if="failedOnes.length">
              <v-alert
                text
                color="warning"
                width="100%"
                class="mt-3"
                variant="outlined"
                density="compact"
                rounded="0"
                border="left"
              >
                <v-row align="center" no-gutters>
                  <v-col cols="11">
                    <v-icon class="mr-2" color="warning">mdi-alert</v-icon>
                    Not all your users were imported.
                    <br />
                    <div class="ml-8">
                      Check the table below for a list of users that have not
                      been imported. To see the reason just hover over the row.
                      You can download the file below and fix the issues then
                      import that file again.
                    </div>
                  </v-col>
                </v-row>
              </v-alert>
              <v-data-table
                :headers="tableHeaders"
                :items="failedOnes"
                item-key="name"
                hover
                class="elevation-1"
              >
                <template v-slot:item="{ item }">
                  <v-tooltip :text="item.error" location="bottom">
                    <template v-slot:activator="{ props }">
                      <tr v-bind="props">
                        <td
                          v-for="(header, index) in tableHeaders"
                          :key="index"
                        >
                          {{ item[header.value] ?? "-" }}
                        </td>
                      </tr>
                    </template>
                  </v-tooltip>
                </template>
                <template #bottom></template>
              </v-data-table>
            </div>
          </v-card-text>
        </v-container>
        <v-card>
          <v-card-actions>
            <v-btn
              variant="elevated"
              color="error"
              tile
              @click="
                bulkImportDialog = false;
                importFile = null;
                failedOnes = [];
              "
              aria-label="cancel"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              variant="elevated"
              v-if="!failedOnes.length"
              color="blue"
              @click="bulkImportUsers"
              :disabled="false"
              :loading="importingProcess"
              class="white--text"
              tile
              aria-label="import"
            >
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
              import
            </v-btn>
            <v-btn
              variant="elevated"
              v-else
              color="success"
              tile
              @click="downloadErrorsCSV"
              aria-label="download errors"
            >
              download errors
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Field from "@/components/Fields.vue";
import Create from "@/components/UserManagerCreate";
import UMSchema from "@/schemas/UserManager";
import { exportCSVFile } from "@/mixins/ExportCSVFile";
import { useDisplay } from "vuetify";

export default {
  name: "UserManager",
  data: () => ({
    height: useDisplay().height,
    appBarBottom: 0,
    dialog: false,
    search: null,
    searchResults: [],
    clients: [],
    fieldSchema: UMSchema,
    validation: false,
    usersByClient: null,
    clientsUsers: null,
    autocompleteUser: null,
    loading: false,
    selectedUser: null,
    clientUserModuleGroups: [],
    usersByClientDialog: false,
    bulkImportDialog: false,
    importingProcess: false,
    importFile: null,
    fileError: "",
    failedOnes: [],
    tableHeaders: [
      { title: "Name", value: "name" },
      { title: "Email", value: "email" },
      { title: "Client_id", value: "client_id" },
      { title: "View reports", value: "view_reports" },
      { title: "Power user", value: "power_user" },
      { title: "Group Administrator", value: "group_administrator" },
    ],
    publicCheckboxDisabled: true,
    clientDisabled: false,
    downloadAllUsersLoading: false,
  }),
  components: {
    Field,
    Create,
  },
  computed: {
    dynamicSize() {
      if (this.failedOnes.length && this.importingProcess) {
        return {
          "min-height": "50vh",
        };
      }
      return {};
    },
  },
  props: {},
  mounted() {
    this.updateAppBarBottom();
    window.addEventListener("resize", this.updateAppBarBottom);
    this.getClients();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateAppBarBottom);
  },
  methods: {
    updateAppBarBottom() {
      this.appBarBottom = document
        .querySelector("#appBar")
        .getBoundingClientRect().bottom;
    },
    getUser() {
      if (this.autocompleteUser) {
        this.selectedUser = null;
        this.emit.emit("systemBusy", true);
        this.$axios
          .get("/users-full-fat/" + this.autocompleteUser.value)
          .then(
            function (response) {
              this.emit.emit("systemBusy", false);
              if (!response.data) {
                this.emit.emit("systemMessage", {
                  title: "User does not exist!",
                  message: "Error! The user you selected does not exist",
                  timeout: -1,
                  colour: "red",
                });
              } else {
                // handle success
                this.selectedUser = response.data;
                this.getClientUserModuleGroups();
              }
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.emit.emit("systemBusy", false);
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Failed to get clients users",
                timeout: -1,
                colour: "red",
              });
            }.bind(this),
          );
      } else {
        this.selectedUser = null;
      }
    },
    downloadAllUsers() {
      this.downloadAllUsersLoading = true;
      this.$axios
        .get("/user-details-full")
        .then(
          function (response) {
            // build the “headers” array by using the keys from response
            let headers = Object.keys(response.data[0]);

            let data = response.data;
            let d = new Date();
            let filename = `full_client_details_${d.getDate()}_${d.toLocaleString("default", { month: "long" })}_${d.getFullYear()}`;

            // call this
            exportCSVFile(headers, data, filename);
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get all user data",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        )
        .finally(() => {
          this.downloadAllUsersLoading = false;
        });
    },
    getUsersByClient() {
      this.emit.emit("systemBusy", true);
      this.$axios
        .get("/users/clients-users/" + this.usersByClient.id)
        .then(
          function (response) {
            // handle success
            this.clientsUsers = response.data;
            this.usersByClientDialog = true;
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemBusy", false);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get clients users",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    resetPassword() {
      if (confirm("Do you really want to send password reset email?")) {
        let resetPasswordEmail = {
          email: this.selectedUser.email,
        };
        // make the call!
        this.$axios
          .post("/logged-password-reset-request", resetPasswordEmail)
          .then(() => {
            this.emit.emit("systemMessage", {
              message: "Check email: " + resetPasswordEmail.email,
              title: "Success!",
              timeout: 4000,
              colour: "blue",
            });
          })
          .catch((error) => {
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Password reset failed",
              timeout: -1,
              colour: "red",
            });
            this.apiValidationErrors(error.response.data.errors);
          });
      }
    },
    searchUsers(v) {
      this.loading = true;
      this.$axios
        .get("/users/search/show-client/" + v)
        .then(
          function (response) {
            // handle success
            this.searchResults = response.data;
            this.loading = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            this.loading = false;
            // handle error
            console.error(error);
          }.bind(this),
        );
    },
    deleteUser() {
      if (confirm("Do you really want to delete this user?")) {
        this.emit.emit("systemBusy", true);
        this.emit.emit("systemMessage", {
          title: "Deleting User",
          colour: "warning",
          timeout: -1,
        });
        // make the call!
        this.$axios
          .delete("/users/" + this.selectedUser.id)
          .then(() => {
            // now update all of this
            this.selectedUser = null;
            this.autocompleteUser = null;
            this.searchResults = [];
            this.emit.emit("systemMessage", {
              title: "Update Complete",
              message: "Success!",
              timeout: 3000,
              colour: "green",
            });
            this.emit.emit("systemBusy", false);
          })
          .catch((error) => {
            this.emit.emit("systemBusy", false);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! User delete failed",
              timeout: -1,
              colour: "red",
            });
            this.apiValidationErrors(error.response.data.errors);
          });
      }
    },
    updateUser() {
      // reset these
      this.resetApiValidationErrors();
      // if this passes validation then call the api
      this.$refs.form.validate().then(({ valid: isValid }) => {
        if (isValid) {
          this.emit.emit("systemBusy", true);

          // make the call!
          this.$axios
            .put("/update/via-user-manager", this.selectedUser)
            .then((response) => {
              // now update all of this
              if (response.data === null) {
                this.autocompleteUser = null;
                this.selectedUser = null;
                this.search = null;
              } else {
                this.selectedUser = response.data;
              }
              this.getClientUserModuleGroups();
              this.emit.emit("systemMessage", {
                title: "Update Complete",
                message: "Success!",
                timeout: 3000,
                colour: "green",
              });
              this.emit.emit("systemBusy", false);
            })
            .catch((error) => {
              this.emit.emit("systemBusy", false);
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Some or all of the User update failed",
                timeout: -1,
                colour: "red",
              });
              this.apiValidationErrors(error.response.data.errors);
            });
        }
      });
    },
    getClients() {
      this.$axios
        .get("/clients")
        .then(
          function (response) {
            // handle success
            this.clients = response.data;
            this.fieldSchema.client_id.selectItems = this.clients;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get all clients",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    getClientUserModuleGroups() {
      if (!this.selectedUser) {
        this.clientUserModuleGroups = [];
        this.fieldSchema.client_user_module_groups.selectItems = [];
      } else {
        this.$axios
          .get("/client-user-module-groups-all/" + this.selectedUser.client_id)
          .then(
            function (response) {
              // handle success
              this.clientUserModuleGroups = response.data;
              this.fieldSchema.client_user_module_groups.selectItems =
                response.data;
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Failed to get all clients",
                timeout: -1,
                colour: "red",
              });
            }.bind(this),
          );
      }
    },
    apiValidationErrors(errors) {
      for (const field in errors) {
        this.fieldSchema[field].apiResponseError = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (var field in this.fieldSchema) {
        this.fieldSchema[field].apiResponseError = null;
      }
    },
    bulkImportUsers() {
      // have you selected a file?
      if (!this.importFile) {
        this.fileError = "Please select a file";
        return;
      }

      this.importingProcess = true;

      // turn the file data into an array of objects
      const file = this.importFile;
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        // get the data
        const csvData = reader.result;
        const data = [];
        const allTextLines = csvData.split(/\r\n|\n/);
        const headers = allTextLines[0].split(",");
        for (let i = 1; i < allTextLines.length; i++) {
          // split content based on comma
          const dataRow = allTextLines[i].split(",");
          if (dataRow.length === headers.length) {
            const csvRecord = {};
            for (let j = 0; j < headers.length; j++) {
              // trim, lowercase and replace spaces with the underscore for every key
              headers[j] = headers[j].toLowerCase().trim().replace(" ", "_");

              // validate the module groups if needed and set the value to the cell
              csvRecord[headers[j]] =
                dataRow[j].toLowerCase() === "true"
                  ? true
                  : dataRow[j].toLowerCase() === "false"
                    ? false
                    : dataRow[j] === ""
                      ? false
                      : dataRow[j];
            }
            data.push(csvRecord);
          }
        }

        // now send the data to the api
        this.$axios
          .post("/bulk-new-users", data)
          .then(() => {
            this.emit.emit("systemMessage", {
              title: "Users imported successfully",
              message: "Success!",
              timeout: 3000,
              colour: "green",
            });

            this.bulkImportDialog = false;
            this.failedOnes = [];
          })
          .catch((error) => {
            if (error.response.status === 400) {
              // invalid data
              this.emit.emit("systemMessage", {
                message:
                  "Check the table for a list of users that have not been imported",
                title: "We couldn't import some users",
                timeout: 4000,
                colour: "warning",
              });
              // show the failed ones to user
              this.failedOnes = error.response.data;
              this.bulkImportDialog = true;
            } else {
              // something went wrong...
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Users import failed",
                timeout: -1,
                colour: "red",
              });
            }
          })
          .finally(() => {
            this.importFile = null;
            this.importingProcess = false;
          });
      };
    },
    downloadErrorsCSV() {
      // build the csv file
      const headers = [
        "Name",
        "Email",
        "Client ID",
        "View Reports",
        "Power User",
        "Group Administrator",
        "Issue",
      ];

      let csvContent = "data:text/csv;charset=utf-8,";

      // Add headers to the CSV content
      csvContent += headers.join(",") + "\n";

      // Add data rows to the CSV content
      this.failedOnes.forEach((row) => {
        const rowData = [
          row.name,
          row.email,
          row.client_id,
          row.view_reports,
          row.power_user,
          row.group_administrator,
          row.error,
        ];
        csvContent += rowData.join(",") + "\n";
      });

      // Create a download link for the CSV file
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "import_users_failed_ones.csv");
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      this.failedOnes = [];
      this.bulkImportDialog = false;
    },
    getUsersPermissionByClient() {
      this.$axios
        .get(
          "/get-users-permission-by-client/" +
            this.selectedUser.id +
            "/" +
            this.selectedUser.client_id,
        )
        .then(
          function (response) {
            // handle success
            this.selectedUser.users_module_group_membership =
              response.data.id ?? null;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed",
              timeout: -1,
              colour: "info",
            });
          }.bind(this),
        );
    },
  },
  watch: {
    // Clear search when dialog is opened
    usersByClientDialog(val) {
      if (!val) {
        this.usersByClient = null;
      }
    },
    // If option is selected, get corresponding data
    usersByClient(val) {
      if (val) {
        this.getUsersByClient();
      }
    },
    autocompleteUser(val) {
      if (val) {
        this.getUser();
      }
    },
    search(val) {
      if (
        !this.autocompleteUser ||
        typeof this.autocompleteUser.text === "undefined" ||
        val != this.autocompleteUser.text
      ) {
        val && this.searchUsers(val);
      }
    },
    "selectedUser.is_public_site_user_profile": function (newVal) {
      if (newVal) {
        this.selectedUser.users_module_group_membership = null;
      }
      this.clientDisabled = !!newVal;
    },
    "selectedUser.client_id": function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.getClientUserModuleGroups();
        this.getUsersPermissionByClient();

        const isPublicSite = this.fieldSchema.client_id.selectItems.find(
          (item) => item.id === this.selectedUser.client_id,
        ).is_public_site;

        this.publicCheckboxDisabled = !isPublicSite;
      }
    },
  },
};
</script>

<style scoped></style>
